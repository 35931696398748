import moment from 'moment';
import writeXlsxFile from 'write-excel-file'

export const useDonsExportExcel = async (data: any) => {

    const schema = [
        {
          column: 'Transaction',
          type: String,
          value: (don: any) => don.refId,
          length: 600
        },
        {
          column: 'Status',
          type: String,
          value: (don: any) => statusString(don.status) 
        },
        {
          column: 'Date',
          type: String,
          value: (don: any) => moment(don.createdAt).format("DD/MM/yyyy")
        },
        {
          column: 'Montant',
          type: String,
          value: (don: any) => don.amount
        },
        {
            column: 'Association',
            type: String,
            value: (don: any) => don._association.firstName
        },
        {
            column: 'Donateur',
            type: String,
            value: (don: any) => `${don._donator.firstName} ${don._donator.lastName}`
        },
    ]

    await writeXlsxFile(data, {
        schema: schema,
        fileName: 'dons.xlsx'
    })
}

const statusString = (status: number) => {
  if(status == 0) return 'En attente'
  if(status == 1) return 'Succès'
  if(status == 2) return 'Echec'
  if(status == 3) return 'Remboursée'
  return;
}