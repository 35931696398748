import React, { HTMLAttributes, useMemo, useState } from "react"
import { BaseControl, BaseControlProps } from "./BaseControl"
import 'react-phone-number-input/style.css'
import { DefaultInputComponentProps, parsePhoneNumber } from 'react-phone-number-input'
import { CountryCode, E164Number } from "libphonenumber-js/types"
import { IconButton, Input } from ".."
import { Dropdown } from "@components/dropdown"
import { getCountryCallingCode, formatIncompletePhoneNumber } from "libphonenumber-js"
import { CountryList } from "./components/CountryList"

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {

    const initialPhoneNumber = useMemo(() => parsePhoneNumber(props.value || ''), [props.value])
    const [country, setCountry] = useState<string>(initialPhoneNumber?.country || props.defaultCountry!)
    const [flagButtonRef, setFlagButtonRef] = useState<HTMLDivElement>()

    return (
        <BaseControl {...props}>

            <div className="flex gap-2">
                <IconButton
                    className="px-4 py-1 bg-white"
                    icon={<img className="h-4" src={`https://cdn.ipregistry.co/flags/wikimedia/${country.toLocaleLowerCase()}.png`} />}
                    onClick={(e: any) => setFlagButtonRef(e.target)}
                />
                <div 
                    className="flex items-center px-4 py-1 bg-white form-group" 
                    onClick={(e: any) => setFlagButtonRef(e.target)}>
                    <span>
                        +{getCountryCallingCode(country as CountryCode || '')}
                    </span>
                </div>

                <Input
                    name={props.name}
                    value={formatIncompletePhoneNumber(props.value! || '', country as CountryCode)}
                    onChange={(value) => props.onChange?.(value, getCountryCallingCode(country as CountryCode || ''))} />
            </div>
            <Dropdown anchorEl={flagButtonRef} open={flagButtonRef !== undefined} onClose={() => setFlagButtonRef(undefined)}>
                <CountryList
                    value={country}
                    addCallingCode
                    onClick={(code) => {
                        setCountry(code as any)
                        setFlagButtonRef(undefined)
                    }}
                />
            </Dropdown>
        </BaseControl>
    )
}

PhoneNumberInput.defaultProps = {
    defaultCountry: 'FR'
}

export type PhoneNumberInputProps = BaseControlProps
    & Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'>
    & DefaultInputComponentProps
    & {
        defaultCountry?: string
        value?: string
        onChange?: (value?: E164Number, country?: any) => void
    }
