import React, { useCallback, useEffect } from "react"
import ReactDOM from "react-dom"
import { GenericSuspense, IconButton } from ".."
import { DialogBody } from "./components/DialogBody"
import { DialogFooter, DialogFooterProps } from "./components/DialogFooter"
import { DialogMask } from "./components/DialogMask"
import { DialogTitle, DialogTitleProps } from "./components/DialogTitle"
import './css/dialog.css'

export const Dialog = (props: DialogProps) => {

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp)
        return () => {
            window.removeEventListener('keyup', handleKeyUp)
        }
    }, [])

    const handleKeyUp = useCallback((e: KeyboardEvent) => {
        if (['Esc', 'Escape'].includes(e.key)) {
            props.onClose?.()
            props.onDismiss?.()
        }
    }, [])

    if (!props.open) {
        return (<></>)
    }

    return ReactDOM.createPortal(
        (
            <DialogMask>
                <DialogBody className={`animated relative bg-white border-primary border-[1px] rounded-[30px] w-full max-w-[400px] ${props.className} ${props.open ? 'animated__fadeInUp' : 'animated__fadeOutDown'}`}>
                    {
                        props.title &&
                        <DialogTitle {...props} />
                    }
                    {
                        props.dismissible !== false &&
                        <IconButton
                            data-testid="dialog-title-button-dismiss"
                            className="absolute top-4 right-4 z-[9999]"
                            icon="fa-solid fa-times"
                            onClick={props.onClose || props.onDismiss}
                        />
    
                    }
                    <GenericSuspense>
                        <div>
                            {
                                props.message && <p className="text-center mt-10">{props.message}</p>
                            }
                            {props.children}
                        </div>
                    </GenericSuspense>
                    {
                        (props.confirmation || props.btnOk || props.btnCancel) &&
                        <DialogFooter {...props} />
                    }
                </DialogBody>
            </DialogMask>
        ),
        document.body
    )
}

export type DialogProps = DialogTitleProps & DialogFooterProps & {
    open: boolean
    className?: string
    message?: string
    confirmation?: boolean
    children?: React.ReactNode
    onClose?: () => void
    /**
     * @deprecated use "onClose" event instead
     */
    onDismiss?: () => void
}
