import { useGenericMutation } from "@hooks/query"
import { useRuntimeEnv } from "@hooks/runtime-env"
import { useAjax } from "@hooks/useAjax"
import { useIPAddress } from "@hooks/utils"
import { AxiosResponse } from "axios"
import { useSaveLogs } from "../logs/useSaveLogs"

export const useAssociationCreateMutation = () => {
    const ajax = useAjax()
    const getIpAdress = useIPAddress()
    const {runtimeEnv} = useRuntimeEnv()
    const saveLogs = useSaveLogs()
    return useGenericMutation(async (data: TAssociationCreate) => {
        const ip = await getIpAdress()
        const response = await ajax.post<TAssociationCreate, AxiosResponse<TAssociationCreateResponse>>('backoffice/v1/add/association', {
            ...data,
            ip_address: ip
        })
        await saveLogs({
            event_name: "create",
            metadata: {
                thematic: "associations",
                thematicId: response.data.association.id,
                thematicName: data.first_name,
                author: runtimeEnv.email,
                ip_address: ip
            }
        })
        return response.data
    })
}

export type TAssociationCreate = {
    id?: string
    first_name?: string,
    title?: string
    email: string,
    website?: string,
    color_code?: string,
    description?: string,
    logo?: string,
    bg_img?: string,
    payment_link?: string,
    min_amount?: string,
    max_amount?: string,
    email_notify_transaction?: string,
    last_status_change_at?: string,
    verified_email?: boolean ,
    coinHouseApiKey?: string
    status?: number
}

export type TAssociationCreateResponse = {
    association: {
        id: string
    },
    coinhouseData: {
        id: string
        accountId: string
        name: string
        status: string
        codeProject: string
        typeProject: string
        apiKey: string
        hmacPassword: string
        autorizedByCNHS: boolean
        parameters: {
            gasStation: any
        },
        dateCreated: string
        dateLastUpdated: string
    }
}

