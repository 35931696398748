import { useGenericMutation } from "@hooks/query"
import { useRuntimeEnv } from "@hooks/runtime-env"
import { useAjax } from "@hooks/useAjax"
import { useIPAddress } from "@hooks/utils"
import { useSaveLogs } from "../logs"

export const useVerifyEmailAssociationMutation = () => {
    const ajax = useAjax()
    const {runtimeEnv} = useRuntimeEnv()
    const saveLogs = useSaveLogs()
    const getIpAdress = useIPAddress()
    return useGenericMutation( async (data: any) => {
        const ip = await getIpAdress()
        await ajax.post("/backoffice/v1/send/email/verify", {association_id: data.association_id})
        await saveLogs({
            event_name: "verification.Email",
            metadata: {
                thematic: "associations",
                thematicName: data.association_name,
                thematicId: data.association_id,
                author: runtimeEnv.email,
                ip_address: ip
            }
        })
    }, {
        success_message: "Email envoyé"
    })
}
