import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"
import { useMemo } from "react"
import { TAssociationCreate } from "./useAssociationCreateMutation"

export const useAssociationInfoQuery = (suspense?: boolean) => {
    const ajax = useAjax()

    const slug = useMemo(() => {
        const matches = window.origin.match(/https:\/\/(.*).vadato.io/) || []
        let slug = matches[1]

        if (process.env.NODE_ENV === 'development') {
            slug = 'isa'
        }
        return slug
    }, [])

    return useGenericQuery<TAssociationCreate>('association-details', async () => {
        const params = "association_slug=" + slug
        const res = await ajax.get(`donator/v1/association/info?${params}`)
        return res.data
    }, { suspense, enabled: slug !== undefined, error_message: '' })
}

