import _ from "lodash"
import React, { HTMLAttributes, useCallback } from "react"
import { IconButton } from ".."
import { BaseControl, BaseControlProps } from "./BaseControl"

export const Checkbox = ({ readonly, options, multiple, ...props }: CheckboxProps) => {

    const handleCheckboxClick = useCallback((val: CheckboxOptionProps) => {
        if (multiple) {
            const newValue = props.value || []
            console.log(newValue)
            props.onChange?.(
                newValue?.includes(val.value)
                    ? newValue.filter((item: any) => item !== val.value)
                    : [...newValue, val.value]
            )
        } else {
            props.onChange?.(!_.isEqual(props.value, val.value) ? val.value : undefined)
        }
    }, [multiple, props.value])

    const getCheckStatus = (val: string | number) => {
        if (multiple) {
            return (
                Array.isArray(props.value) && props.value.includes(val)
            )
                ? <img src="/assets/img/icons/checkbox-checked.svg" className="w-[25px] min-w-[25px]" />
                : <img src="/assets/img/icons/checkbox-empty.svg" className="w-[25px] min-w-[25px]" />
        }
        else {
            return _.isEqual(props.value, val)
                ? <img src="/assets/img/icons/checkbox-checked.svg" className="w-[25px] min-w-[25px]" />
                : <img src="/assets/img/icons/checkbox-empty.svg" className="w-[25px] min-w-[25px]" />
        }
    }

    return (
        <BaseControl {...props}>
            <div className="flex flex-col">
                {
                    options?.map((item, index) => (
                        <div key={index} className="checkbox-item flex gap-1 items-center">
                            <IconButton
                                data-testid={`checkbox-option-${index}`}
                                icon={getCheckStatus(item.value)}
                                onClick={() => handleCheckboxClick(item as CheckboxOptionProps)} />
                            <span className="text-[14px]" onClick={() => handleCheckboxClick(item as CheckboxOptionProps)}>{item.label}</span>
                        </div>

                    ))
                }
            </div>
        </BaseControl>
    )
}

export type CheckboxProps = BaseControlProps & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>
    & {
        value?: any | Array<any>
        multiple?: boolean
        options?: Array<CheckboxOptionProps>
        onChange?: (value: CheckboxProps['value']) => void
    }

export type CheckboxOptionProps = {
    value: any
    label: string
    color?: string
}