import React, { HTMLAttributes } from "react"
import { BaseControl, BaseControlProps } from "./BaseControl"
import { Checkbox } from './Checkbox'
import { Input } from './Input'

export const CheckboxInput = (props: CheckboxInputProps) => {
console.log('CheckboxInput', props.value, Boolean(props.value) ? 1 : 0)
    return (
        <BaseControl {...props} label="">
            <div className="flex gap-3">
                <div className="flex-[0.6]">
                    <Checkbox 
                        options={[{ label: props.label!, value: 1 }]} 
                        value={Boolean(props.value) ? 1 : 0} 
                        />
                </div>
                <div className="flex-[0.4]">
                    <Input value={props.value} onChange={(val) => props.onChange?.(val)} />
                </div>
            </div>
        </BaseControl>
    )
}

export type CheckboxInputProps = BaseControlProps
    & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>
    & {
        value: string
        onChange?: (value: CheckboxInputProps['value']) => void
    }
