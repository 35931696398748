import { useGenericMutation } from "@hooks/query";
import { useAjax } from "@hooks/useAjax";

export const useOTPVerificationMutation = () => {
	const ajax = useAjax();
	return useGenericMutation(
		(data: TVerifyOTP) => ajax.post("/auth/v1/verify/otp", data),
		{ success_message: "Code OTP vérifié", onSuccess: (data, variables) => {
            localStorage.setItem('change_password_request_key', data.data?.change_password_request_key)
        } }
	);
};

export type TVerifyOTP = {
	verify_key: string;
	otp: string;
};
