
import { TDonatorCreate, useAssociationInfoQuery } from "@hooks/data"
import { useFormData } from "@hooks/formdata"
import { useTranslations } from "@hooks/translation"
import { useCryptoConvertion } from "@hooks/utils"
import moment from "moment"

export const useCreationFormData = (defaultValue?: Partial<TDonatorCreate>, required?: Array<string>, readonly?: boolean) => {

    const [
        MAJORITY_ERROR,
        MAIL_ERROR,
        MIN_AMOUNT_ERROR
    ] = useTranslations(i18n)
    
    const { data } = useAssociationInfoQuery(true)
    const { convertToEuro } = useCryptoConvertion()

    return useFormData<Partial<TDonatorCreate>>({
        id: 'Vadato.Creation.FormData',
        formData: {
            country_code: '33',
            dob: moment('1990/11/11').format('YYYY-MM-DD'),
            nationality: 'FR',
            ...defaultValue
        },
        alpha: [
            'first_name',
            'last_name'
        ],
        readonly,
        validateOnChange: true,
        validateOnBlur: true,
        required: required || [
            'first_name',
            'last_name',
            'title',
            'address.address',
            'address.postal_code',
            'address.city',
            'dob',
            'crypto_coin',
            'nationality',
            'phone',
            'country_code',
            'wallet_address',
            'email',
            'amount',
        ],
        validate: (name, value, formData) => {
            
            if (name === 'dob' && moment(value).isAfter(moment().subtract(18, 'years'))) {
                return MAJORITY_ERROR
            }
            if (name === 'email' && !/^\s*[a-zA-Z0-9\._%+-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,4}\s*$/.test(value)) {
                return MAIL_ERROR
            }

            if (name === 'amount' && convertToEuro(formData?.amount || 0, formData?.crypto_coin || 'BTC') < parseFloat(data?.min_amount || '0')) {
                return MIN_AMOUNT_ERROR.replace('$1', data?.min_amount || '')
            }

            return ''
        },
    })
}

const i18n = [
    'Vadato.Donators.Create.Form.Birthday.Majority.Error',
    'Vadato.Donators.Create.Form.Email.Invalid.Error',
    'Vadato.Donators.Create.Form.MinAmont.Error'
]
