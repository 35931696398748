import { useGenericQuery } from "@hooks/query"
import { useUrlParamsEncode } from "@hooks/url/useUrlParamsEncode"
import { useAjax } from "@hooks/useAjax"
import _ from "lodash"

export const useAssociationsQuery = (filter?: any, suspense?: boolean) => {
    const ajax = useAjax()
    const encodeUrl = useUrlParamsEncode()
    console.log(filter)
    return useGenericQuery(['associations_query', filter] ,async () => {
        try {
            const res = await ajax.get(`/v1/associations/list?${encodeUrl({filter: JSON.stringify({data: _.omit(filter.data, ['pageSize', 'page']), sort: filter.sort})})}`)
            return res
        } catch (error) {
            return []
        }
    }, {
        suspense, 
        enabled: !_.isNil(filter) && !_.isNil(filter.sort)
    })
}

